import { useTranslation } from "react-i18next";

// components
import ContainerHome from "../components/ContainerHomePage";
import { Link as RouterLink } from "react-router-dom";

// material
import {
  Stack,
  Box,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Grow,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";

const BoxSummary = styled(Box)(({ theme }) => ({
  width: "500px",
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
}));

export default function SummaryPage() {
  const { t } = useTranslation();

  return (
    <ContainerHome>
      <Grow in timeout={1000}>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
          }}
        >
          <Stack spacing={4}>
            <Stack
              rection="column"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Typography
                variant="h1"
                style={{ fontSize: "30px", width: "100%" }}
                color="primary"
              >
                {t("summaryPage.title")}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ width: "100%" }}
              >
                {t("summaryPage.subtitle")}
              </Typography>
              <BoxSummary style={{ width: "100%" }}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_1")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_2")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_3")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_4")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_5")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneIcon sx={{ color: "#fff" }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary={t("summaryPage.item_6")}
                    />
                  </ListItem>
                </List>
              </BoxSummary>

              <Button
                type="button"
                variant="contained"
                color="secondary"
                size="large"
                component={RouterLink}
                style={{ marginBottom: "40px" }}
                to={"/"}
              >
                {t("buttons.farther")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Grow>
    </ContainerHome>
  );
}
