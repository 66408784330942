import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import ContainerHome from "../components/ContainerHomePage";
import BoxStyled from "../components/Box";
import LanguagePopover from "../components/LanguagePopover";

// material
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Grow } from "@mui/material";

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <ContainerHome>
      <BoxStyled>
        <Stack spacing={3}>
          <LanguagePopover />
          <Grow in timeout={1000}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={15}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Typography variant="h1" color="primary">
                  {t("homePage.title")}
                </Typography>
                <Typography variant="h2" color="primary">
                  {t("homePage.subtitle")}
                </Typography>
                <Typography variant="h2" color="primary">
                  {t("homePage.subtitle_2")}
                </Typography>
              </Stack>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                size="large"
                component={RouterLink}
                to={"/quiz"}
              >
                {t("buttons.start")}
              </Button>
            </Stack>
          </Grow>
        </Stack>
      </BoxStyled>
    </ContainerHome>
  );
}
