import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";

// components
import i18n from "i18next";

import MenuPopover from "../components/MenuPopover";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
  },
  {
    value: "ua",
    label: "Ukrainian",
    icon: "/static/icons/ic_flag_ua.svg",
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const localStorageLang = localStorage.getItem("lang") || "ua";
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (option) => {
    const selected = option.value;
    if (selected) {
      localStorage.setItem("lang", selected);
      i18n.changeLanguage(selected);
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        className="language"
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <img
          src={LANGS.find((el) => el.value === localStorageLang).icon}
          alt={LANGS.find((el) => el.value === localStorageLang).label}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={
                option.value ===
                LANGS.find((el) => el.value === localStorageLang).value
              }
              onClick={() => handleClose(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon onClick={() => handleClose(option)}>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon>
              <ListItemText
                onClick={() => handleClose(option)}
                primaryTypographyProps={{ variant: "body2" }}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
