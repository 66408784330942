import { Zoom, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function CheckedIcon() {
  return (
    <Zoom in timeout={200}>
      <Box
        sx={{
          position: "absolute",
          zIndex: "10",
          right: " 10px",
          top: "10px",
          backgroundColor: "red",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CheckIcon
          fontSize="large"
          sx={{
            color: "#fff",
          }}
        />
      </Box>
    </Zoom>
  );
}
