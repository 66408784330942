
import './App.css';
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import Router from "./router";
import LanguagePopover from "./components/LanguagePopover";


function App() {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <Router />
      <LanguagePopover />
    </ThemeConfig>
  );
}

export default App;
