import { styled, useTheme } from "@mui/material/styles";

const BoxStyled = styled("div")(({ theme }) => ({
  overflow: "auto",
  height: "-webkit-fill-available",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "900px",
  marginTop: "auto",
  marginBottom: "auto",
  paddingTop: "30px",
  paddingBottom: "30px",
  textAlign: "center",
}));

export default function Box({ children }) {
  return <BoxStyled>{children}</BoxStyled>;
}
