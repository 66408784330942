import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material
import { CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  TextField,
  useMediaQuery,
  Fade,
} from "@mui/material";
import CheckedIcon from "./CheckedIcon";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const CardConteiner = styled(Card)(({ theme }) => ({
  width: "24%",
  height: "100%",
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const CardActionAreaContainer = styled(CardActionArea)(({ theme }) => ({
  position: "relative",
  height: "100%",
  display: "-ms-flexbox",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 20px rgb(0 0 0 / 10%)",
  borderRadius: "5px",
  willChange: "transform",
  overflow: "hidden",
  cursor: "pointer",
  transition: "all .35s ease",
}));

export default function Form({
  QUIZ,
  questionsIndex,
  handleChange,
  grow,
  questionsIndexIncrement,
  questionsIndexDecrement,
  submitData,
}) {
  const [values, setValues] = useState({
    city: "",
    phone: "",
  });

  const { t } = useTranslation();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const submitHandler = () => {
    submitData(values);
  };

  const handleChangeInput = (e, key) => {
    setValues({
      ...values,
      [key]: typeof e === "object" ? e.target.value : e,
    });

    questionsIndex === 5
      ? (QUIZ[questionsIndex].acceptedAnswer = values.city)
      : (QUIZ[questionsIndex].acceptedAnswer = values.phone);
  };

  const isPossible = values.phone && isPossiblePhoneNumber(values.phone) ? true : false

  return (
    <>
      {QUIZ[questionsIndex]["answers"].length ? (
        <Stack
          direction={(smDown && "column") || "row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {QUIZ[questionsIndex]["answers"].map((el, index) => {
            return (
              <Fade key={index} in={grow}>
                <CardConteiner
                  onClick={() => handleChange(el.subtitle)}
                  style={{ "justifyContent": "flex-start" }}
                >
                  {QUIZ[questionsIndex].acceptedAnswer === el.subtitle && (
                    <CheckedIcon />
                  )}
                  <CardActionAreaContainer className="cardActionCustom">
                    <CardMedia
                      component="img"
                      height="210"
                      image={el.img}
                      alt="children"
                    />
                    <div className="cardContentCustom">
                      <CardContent
                        className="cardContentCustom"
                        sx={{ textAlign: "center" }}
                      >
                        {t(el.subtitle)}
                      </CardContent>
                    </div>
                  </CardActionAreaContainer>
                </CardConteiner>
              </Fade>
            );
          })}
        </Stack>
      ) : questionsIndex === 5 ? (
        <Fade in={grow}>
          <TextField
            placeholder={t("quizBlock.placeholderCity")}
            // placeholder="You city"
            variant="outlined"
            fullWidth
            name="city"
            required
            value={values.city}
            onChange={(e) => handleChangeInput(e, "city")}
            sx={{
              backgroundColor: "#fff",
              overflow: "hidden",
              borderRadius: "7px",
            }}
          />
        </Fade>
      ) : (
        <Fade in={grow}>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="UA"
            limitMaxLength
            placeholder="You telephone"
            variant="outlined"
            name="phone"
            required
            value={values.phone}
            autoComplete="off"
            onChange={(e) => handleChangeInput(e, "phone")}
          />
        </Fade>
      )}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className="botomButtons"
      >
        {questionsIndex > 0 ? (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            onClick={questionsIndexDecrement}
          >
            {t("buttons.cancel")}
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            component={RouterLink}
            to={"/"}
          >
            {t("buttons.cancel")}
          </Button>
        )}

        {questionsIndex < 6 ? (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            onClick={
              QUIZ[questionsIndex].acceptedAnswer
                ? questionsIndexIncrement
                : null
            }
          >
            {t("buttons.farther")}
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            // component={QUIZ[questionsIndex].acceptedAnswer && isPossible ? RouterLink : null}
            // onClick={QUIZ[questionsIndex].acceptedAnswer && isPossible ? submitHandler : null}
            component={isPossible ? RouterLink : null}  
            onClick={isPossible ? submitHandler : null}
            to={"/summary"}
          >
            {t("buttons.farther")}
          </Button>
        )}
      </Stack>
    </>
  );
}
