export let QUIZ = [
  {
    questions: 'quiz.questions_1',
    answers: [
      {
        img: "/static/img_5-6_years.jpg",
        subtitle: 'quiz.answer_1_1',
      },
      {
        img: "/static/img_7-10_years.jpg",
        subtitle: 'quiz.answer_1_2',
      },
      {
        img: "/static/img_11-12_years.jpg",
        subtitle: 'quiz.answer_1_3',
      },
    ],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_2',
    answers: [
      {
        img: "/static/kindergarten.jpg",
        subtitle: 'quiz.answer_2_1',
      },
      {
        img: "/static/primary_school.jpg",
        subtitle: 'quiz.answer_2_2',
      },
      {
        img: "/static/secondary_school.jpg",
        subtitle: 'quiz.answer_2_3',
      },
    ],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_3',
    answers: [
      {
        img: "/static/can't_count.jpg",
        subtitle: 'quiz.answer_3_1',
      },
      {
        img: "/static/counts_up_to_10.jpg",
        subtitle: 'quiz.answer_3_2',
      },
      {
        img: "/static/counts_over_10.jpg",
        subtitle: 'quiz.answer_3_3',
      },
    ],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_4',
    answers: [
      {
        img: "/static/hear_for_the_first_time.jpg",
        subtitle: 'quiz.answer_4_1',
      },
      {
        img: "/static/seen_on_TV.jpg",
        subtitle: 'quiz.answer_4_2',
      },
      {
        img: "/static/child_of_acquaintances.jpg",
        subtitle: 'quiz.answer_4_3',
      },
    ],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_5',
    answers: [
      {
        img: "/static/add_up_big_numbers.jpg",
        subtitle: 'quiz.answer_5_1',
      },
      {
        img: "/static/count_and_recite_a_verse.jpg",
        subtitle: 'quiz.answer_5_2',
      },
      {
        img: "/static/perseverance.jpg",
        subtitle: 'quiz.answer_5_3',
      },
      {
        img: "/static/all_together.jpg",
        subtitle: 'quiz.answer_5_4',
      },
    ],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_6',
    answers: [],
    acceptedAnswer: null,
  },
  {
    questions: 'quiz.questions_7',
    answers: [],
    acceptedAnswer: null,
  },
];
