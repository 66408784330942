import React from "react";
import { BrowserRouter } from "react-router-dom";
import i18n from "./i18n";
import "./index.css";
import App from "./App";
// import store from "./store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";



function AppWithCallbackAfterRender() {
  return (
    // <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
    // </Provider> 
  );
}
const root = createRoot(document.getElementById("root"));
root.render(<AppWithCallbackAfterRender />);
