import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

// components
import ContainerHome from "../components/ContainerHomePage";
import Form from "./Form";
import LanguagePopover from "./LanguagePopover";

// material
import { Stack, Typography, LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { QUIZ } from "../constants/dataQuiz";

const animationTimeout = 300;

const BoxLinearProgress = styled(Box)(({ theme }) => ({
  width: "600px",
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
}));

export default function QuizBlock() {
  const [questionsIndex, setQuestionsIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [progress, setProgress] = useState(12);
  const [grow, setGrow] = useState(true);
  const { t } = useTranslation();

  const handleChange = (answer) => {
    setSelectedValue(answer);
    QUIZ[questionsIndex].acceptedAnswer = answer;
  };

  const submitData = (data) => {
    const answers = {
      age_student: t(QUIZ[0].acceptedAnswer),
      type_education: t(QUIZ[1].acceptedAnswer),
      numeracy: t(QUIZ[2].acceptedAnswer),
      mental_arithmetic: t(QUIZ[3].acceptedAnswer),
      desired_result: t(QUIZ[4].acceptedAnswer),
    };

    const answersQuiz = { ...data, ...answers };
    // console.log(answersQuiz);

    axios
      .post("https://api-test.soroban.ua/panel/quiz/mail", answersQuiz)
      .then((response) => {
        console.log(response);
      });
  };

  const questionsIndexIncrement = () => {
    setTimeout(() => {
      setSelectedValue("");
      setQuestionsIndex((state) => state + 1);
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 12 : prevProgress + 12
      );
    }, animationTimeout);

    processAnimation();
    window.scrollTo(_, 0);
  };

  const processAnimation = () => {
    setGrow(false);
    setTimeout(() => {
      setGrow(true);
    }, animationTimeout);
  };

  const questionsIndexDecrement = () => {
    setTimeout(() => {
      setSelectedValue("");
      setQuestionsIndex((state) => state - 1);
      setProgress((prevProgress) => prevProgress - 12);
    }, animationTimeout - 100);

    processAnimation();
    window.scrollTo(_, 0);
  };

  return (
    <ContainerHome>
      <Stack spacing={3}>
        <LanguagePopover />
        <Stack justifyContent="center" alignItems="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Typography variant="h3" color="primary">
              {t("quizBlock.step")} {questionsIndex + 1} / 7
            </Typography>
            <BoxLinearProgress>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="secondary"
              />
            </BoxLinearProgress>
            <Typography variant="h3" color="primary">
              {t(QUIZ[questionsIndex]["questions"])}
            </Typography>
            <Form
              QUIZ={QUIZ}
              questionsIndex={questionsIndex}
              grow={grow}
              handleChange={handleChange}
              questionsIndexIncrement={questionsIndexIncrement}
              questionsIndexDecrement={questionsIndexDecrement}
              submitData={submitData}
            />
          </Stack>
        </Stack>
      </Stack>
    </ContainerHome>
  );
}
