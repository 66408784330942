import { styled } from "@mui/material/styles";

const ContainerHome = styled("div")(({ theme }) => ({
  padding: "15px 20px",
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "space-around",
  [theme.breakpoints.down("sm")]: {
    // padding: "40px",
    height: "unset",
  },
}));

export default function ContainerHomePage({ children }) {
  return <ContainerHome>{children}</ContainerHome>;
}
