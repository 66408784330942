import { Navigate, useRoutes } from "react-router-dom";

// components
import HomePage from "./pages/HomePage";
import QuizPage from "./pages/QuizPage";
import SummaryPage from "./pages/SummaryPage"


// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/quiz",
            element: <QuizPage />,
        },
        {
            path: "/summary",
            element: <SummaryPage />,
        },
        // { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
